<template>
  <v-carousel
    v-model="model"
    height="300"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="gallery in galleries"
      :key="gallery.slug"
      :src="gallery.photo"
      :to="redirectToExternalPage() ? '' : `/photo/${gallery.slug}`"
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <div class="display-3 text-h6 text-uppercase">
          <v-overlay
            absolute
          >
            {{ gallery.name }}
          </v-overlay>
        </div>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'GalleryCarousel',
  props: {
    galleries: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data () {
    return {
      model: 0
    }
  },
  methods: {
    redirectToExternalPage() {
      const EXTERNAL_ROUTE_NAME = "external.readnews";

      const currentRoutName = this.$router?.history?.current?.name;

      const redirectToExternalComplianceRead =
        currentRoutName === EXTERNAL_ROUTE_NAME;

      return redirectToExternalComplianceRead;
    },
  },
}
</script>
